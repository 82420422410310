@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  background-color: #212529;
}

.mainContainer {
  flex: 1;
  background-color: white;
}
